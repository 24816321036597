.profile-container {
    background: white;
    border-radius: 8px;
    margin: 10px;
    box-shadow: 10px 6px 15px 10px rgba(0, 0, 0, 0.04);
    padding: 10px;
}

.profile-pic-container{
    width: 92px;
    height: 92px;
    border-radius: 46px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: -50px;
    transform: translateX(-50px);
    box-shadow: 10px 6px 15px 10px rgba(0, 0, 0, 0.04);
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.06);
    height: 50px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #000;

    .btn-title {
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

}