.promotion-item {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid #e0e0e0;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.07);
  margin-bottom: 10px;
  border-radius: 10px;
  gap: 10px;

  & > * {
    margin: 0px;
  }

  & > h2 {
    font-size: 20px;
    font-weight: 500;
    color: #1b1a20;
    display: flex;
    align-items: center;
    gap: 5px;
    text-transform: capitalize;
  }

  & > .sub-title {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }

  & > .button-group {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    button {
      border-radius: 20px;
      font-size: 14px;
      width: 130px;
      padding-top: 2px;
      padding-bottom: 2px;

      &.primary {
        background-color: #FF6B15;
        color: white;
      }
      &.secondary:hover {
        border-color: #FF6B15;
        color: #FF6B15;
      }
      &:disabled {
        opacity: 0.4;
      }
    }
  }
}

.modal-container {
    padding-bottom: 60px;
}

.modal-header {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  h2 {
    font-size: 24px;
    font-weight: 500;
    color: #1b1a20;
    text-align: center;
    text-transform: capitalize;
  }
}

.info-box {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    & > * {
        margin: 0px;
    }
    & > h2 {
        font-size: 20px;
        font-weight: 500;
        color: #1b1a20;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    & > p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
    }
}