.home-item-card {
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  width: 100%;


  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 16px 16px 0px 0px;
    background: linear-gradient(
      209deg,
      rgba(0, 0, 0, 0.49) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .action-buttons {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;

    img:nth-child(1) {
      margin-right: 15px;
    }
  }

  .content {
    padding: 10px;
    .container {
      display: flex;
      align-items: center;
      img {
        width: 60px;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border: 1px solid #ccc;
        border-radius: 99px;
        margin-right: 16px;
      }
    }
  }

  .title {
    color: #433f55;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin: 0px;
    margin-bottom: 5px;
    /* 130% */
  }

  .description, .address {
    color: #433f55;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@media screen and (max-width: 600px) {
  .home-item-card {
    width: 100%;
  }
}
