.cart-container {
  margin: 10px;

  .title {
    color: #605d6c;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.28px;
    text-align: center;
    margin: 0;
    margin-top: 10px;
  }

  .add-item {
    color: $primary-color;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: block;
  }

  .items-container,
  .invoice-container {
    border-radius: 8px;
    background: #ffffff;
    padding: 15px;
    box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .invoice-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px 0px 5px;
    margin-bottom: 5px;

    .subtotal {
      color: #1b1a20;
      font-style: normal;
      line-height: normal;
    }

    .total {
      color: #1b1a20;
      font-style: normal;
      line-height: normal;
      font-weight: 600;
    }

    .invoice-price {
      color: $primary-color;
      text-align: right;
      font-style: normal;
      line-height: normal;
    }

    .total-price {
      color: #1b1a20;
      text-align: right;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .invoice-title {
      font-style: normal;
      line-height: normal;
    }
  }
}

.cart-item-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .first {
    display: flex;
    align-items: baseline;

    img {
      margin-right: 5px;
    }

    p {
      margin: 5px 0px 5px 0px;
    }

    .name {
      color: #1b1a20;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .price {
      color: #1b1a20;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .quantity {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid #000;
      background: rgba(0, 0, 0, 0.04);
      color: #1b1a20;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .description {
      color: #605d6c;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      /* 150% */
    }

    .customize-container {
      display: flex;
      align-items: center;
      cursor: pointer;

      .customize {
        color: $primary-color;
        font-size: 16px;
      }
    }
  }

  .second {
    .control-button {
      border-radius: 8px;
      border: 1px solid $primary-color;
      padding: 5px;
      display: flex;
      align-items: center;
      font-size: 13px;
    }

    .qty-btn {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .price {
      color: #1b1a20;
      font-weight: 500;
      margin-top: 5px;
      font-size: 16px;
      text-align: right;
    }
  }
}
