.header-container {
  height: 30px;
  display: flex;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  position: sticky;
  top: 0;
  z-index: 1000;

  .nav-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
  }

  .sub-container {
    display: flex;
    align-items: center;
  }

  .detail-container {
    .first {
      display: flex;
      align-items: center;

      .avatar-container {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 50%;
        // border: 3px solid $primary-color;

        img {
          width: inherit;
          height: inherit;
          object-fit: contain;
        }
      }

      .title {
        // color: $primary-color;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 5px;
      }
    }

    .second {
      display: flex;
      align-items: center;

      .location-container {
        display: flex;
        align-items: center;

        img {
          margin-right: 5px;
        }
      }

      .location {
        color: #222130;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .reviews {
        color: #222130;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        margin-left: 5px;
      }
    }
  }
}
