.item-container {
  display: flex;
  // align-items: flex-start;
  // margin: 5px;
  // justify-content: space-between;
  // padding-bottom: 30px;
  // border-bottom: 1px dotted #B6B4BF;

  border-bottom: 1px solid rgb(238,238,238);
  margin-bottom: 20px;

  &.disable-item {
    opacity: 0.5;
    pointer-events: none;
  }
  
  &:not(:has(.image>img)) .add-button-container {
    bottom: 0px;
  }

  &:not(:has(.image>img)) {
    padding-bottom: 30px;
  }


  .first {
    flex: 1;

    .title {
      color: #433f55;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      /* 130% */
    }

    .price {
      color: $primary-color;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      /* 80% */
    }

    .description {
      color: #605d6c;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .promotion {
      display: flex;
      align-items: center;

      .promotion-text {
        border-radius: 4px;
        background: $primary-color;
        color: #fff;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-right: 2px;
        padding-left: 2px;
        margin-left: 5px;
      }
    }
  }

  .second {
    // flex: 0.4;
    // position: relative;
    // margin-left: 12px;
    // max-height: 164px;
    // min-width: 156px;

    .add-button-container {
      position: relative;
      left: 50%;
      bottom: 28px;
      transform: translateX(-50%);
      // z-index: 1;

      .first {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
      }

      .second {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(226, 226, 231);
        height: 30px;
        border-radius: 8px;
        width: 90px;
        position: relative;
        overflow: hidden;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        box-shadow: rgba(40, 44, 63, 0.08) 0px 3px 8px;
      }

      .btn {
        appearance: none;
        background: transparent;
        box-shadow: none;
        border: none;
        height: 100%;
        cursor: pointer;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-transform: uppercase;
      }

      .btn2 {
        position: absolute;
        inset: 0px;
      }

      .btn-txt {
        -webkit-font-smoothing: antialiased;
        font-family: Poppins;
        font-weight: 800;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
        text-transform: uppercase;
        color: $primary-color;
      }

      .first-digit {
        transition: all 100ms ease-in-out 0s;
        height: 40px;
        cursor: none;
        pointer-events: none;
        transform: translateY(-40px);
        position: absolute;
        width: 100%;

        // &.act {
        //     transform: translateY(0px) !important;
        // }
      }

      .second-digit {
        width: 90px;
        position: absolute;
        inset: 0px;
        transition: all 100ms ease-in-out 0s;
        transform: translateY(0px);
        text-align: center;
      }

      .third-digit {
        transition: all 100ms ease-in-out 0s;
        height: 40px;
        cursor: none;
        pointer-events: none;
        transform: translateY(40px);
        position: absolute;
        width: 100%;
      }

      .minus {
        z-index: 1;
        padding-left: 16px;
        padding-right: 12px;
        transition: all 100ms ease-in-out 0s;
        transform: translateX(-64px);

        &.act {
          transform: translateX(0px) !important;
        }
      }

      .plus {
        padding-right: 16px;
        padding-left: 12px;
        transition: all 100ms ease-in-out 0s;
        transform: translateX(64px);

        &.act {
          transform: translateX(0px) !important;
        }
      }
    }

    .add-button {
      border-radius: 8px;
      border: 1px solid $primary-color;
      background: $primary-color;
      width: 80px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: absolute;
      bottom: -10px;
      right: 0px;
      // top:0px;
      left: 35px;
      align-self: center;
      cursor: pointer;
    }

    .item-button {
      border-radius: 8px;
      border: 1px solid $primary-color;
      background: white;
      width: 80px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $primary-color;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: absolute;
      bottom: -10px;
      right: 0px;
      // top:0px;
      left: 35px;
      align-self: center;
    }

    .cust-button {
      position: absolute;
      bottom: -25px;
      left: -50%;
      // \\top:5px;
      transform: translateX(-30%);
      left: 35px;
      align-self: center;
      color: #605d6c;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .image {
      // width: 150px;
      // height: 150px;
      flex-shrink: 0;
      overflow: hidden;
      // border-radius: 20px;
      width: 106px;
      border-radius: 12px;
      
      &:has(img) {
        height: 100px;
      }

      img {
        object-fit: cover;
        border-radius: 12px;
      }
    }
  }
}

.styles_divider {
  border-bottom: 0.5px solid #d3d3d3;
  height: 0.5px;
  margin: 20px 0;
}
