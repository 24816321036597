@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf');
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf');
  font-weight: bold;
  font-style: italic, oblique;
}

html,
body {
  margin: 0px;
  font-family: 'Poppins';
}
