.add-item-container {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.1);
    /* height: 100%; */
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 10px auto;
    max-width: 800px;


    .header {
        display: flex;
        justify-content: space-between;
        margin: 10px;
        border-bottom: 1px dotted #B2AECB;
        padding-bottom: 10px;
        align-items: baseline;

        .title {
            margin-bottom: 5px;
            font-size: 22px;
            font-family: Poppins;
            color: #0A0909;
            font-weight: 700;
            border-bottom: 1px
        }

        .desc {
            font-size: 14px;
            font-family: Poppins;
            color: #423E54;
            font-weight: 400
        }

        .close-image {
            width: 30px;
            height: 30px;
          }
    }

    .content {
        flex-grow: 1;
        overflow: scroll;

        .extra-container {
            margin: 10px;
            border-bottom: 1px dotted #B2AECB;
            padding-bottom: 10px;
            align-items: baseline;

            .second-header {
                display: flex;
                justify-content: space-between;
                align-items: baseline;

                .title {
                    font-size: 17px;
                    font-family: Poppins;
                    color: #423E54;
                    font-weight: 700
                }

                .extra-tag {
                    padding: 5px 10px 5px 10px;
                    border: 1px solid;
                    border-radius: 5px;
                    font-family: Poppins;
                    color: #423E54;
                }
            }

            .extra-item-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
                flex-wrap: wrap;
                position: relative;

                .title, .price {
                    font-size: 16px;
                    font-family: Poppins;
                    color: #423E54;
                    font-weight: 400
                }

            }
        }
    }

    .footer {
        flex: 0.1 1;
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;

        .first {
            display: flex;
            align-items: center;

            .plus {
                width: 40px;
                height: 40px;
                border-radius: 20px;
                background-color: #F4F0F7;
                align-items: center;
                justify-content: center;
                display: flex;
                cursor: pointer;
                border: none;
            }

            .minus {
                width: 40px;
                height: 40px;
                border-radius: 20px;
                background-color: #F4F0F7;
                align-items: center;
                justify-content: center;
                display: flex;
                cursor: pointer;
                border: none;
            }

            .quantity {
                margin-left: 5px;
                margin-right: 5px;
                color: #454256;
                font-size: 16px;
                font-family: Poppins;
                font-weight: 400
            }
        }

        .second {
            text-align: center;
            flex: 1;
            margin-left: 10px;
            background: $primary-color;
            height: 40px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            cursor: pointer;
            font-size: 16px;
            font-family: Poppins;
            font-weight: 700;
            text-transform: capitalize;
        }
    }
}