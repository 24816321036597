.track-container {
  padding: 16px;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.06);
    height: 50px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #000;

    .btn-title {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
