.placeorder-button {
    border-radius: 8px;
    background: $primary-color;
    height: 50px;
    max-width: 800px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 5px auto;
    cursor: pointer;

    .first {
        display: flex;
        align-items: center;

        img{
            margin-right: 5px;
        }
    }

    .first p,
    .second,
    .third {
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    @media screen and (max-width: 600px){
        & {
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}