.menu-button {
    border-radius: 8px;
    background: $primary-color;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.06);
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
    align-content: center;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    min-width: 66px;
    max-width: 100px;


    p {
        color: #FFF;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
    }


}

.sheet-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 0px 20px;
    padding-bottom: 10px;
    border-bottom: 1px dotted #B6B4BF;

    .title {
        color: #1B1A20;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
    }

    .description {
        color: #222130;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
    }

    .close-image {
        width: 30px;
        height: 30px;
      }
}

.sheet-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
    margin: 16px 0px 0px 10px;

    &.active {
        border-left: 3px solid #1B1A20;
        .title{
            color: #1B1A20;
            text-align: right;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin: 0px;
            /* 150% */
        }
        
    }
    .title {
        color: #605D6C;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0px;
        /* 150% */
    }

    .count {
        color: #1B1A20;
        text-align: right;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 0px;
        /* 150% */
    }
}

.sheet-container {
    margin: 0 auto;
    max-width: 680px;

    .react-modal-sheet-container {
        background-color: white !important;
    }

    .react-modal-sheet-backdrop {
        background-color: rgba(0, 0, 0, 0.3) !important;
    }

    .react-modal-sheet-drag-indicator {
        background-color: #666 !important;
    }
}