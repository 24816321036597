.searchBar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px;

  input {
    width: 100%;
    border: none;
    background-color: transparent;
    padding: 5px;
    flex: 1;
    font-size: 18px;
    -webkit-appearance: none;
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Standard property */

    :focus-visible {
      outline: none !important;
    }

    ::placeholder {
      color: #b6b4bf;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  img {
    width: 36px;
    height: 36px;
  }
}

input:focus-visible {
  outline: none;
  /* Remove default focus outline for keyboard focus */
  // box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.3); /* Add your custom focus indicator */
}
