.rating {
    width: 47px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 4px;
    background: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        color: #FFF;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}