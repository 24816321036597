@import 'variables';
@import 'rating';
@import 'offer';
@import 'item';
@import 'placeorderButton';
@import 'menuButton';
@import 'cart';
@import 'addItem';
@import 'orders';
@import 'header';
@import 'loader';
@import 'tracker';
@import 'profile';

.home-item-container {
  margin: 10px;
  margin-bottom: 50px;
}

/* Base styles for the grid */
.home-item-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 1 column by default */
  gap: 20px; /* Adjust the gap as needed */
}

/* Tablet (min-width: 768px) */
@media only screen and (min-width: 768px) {
  .home-item-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
  }
}

/* Desktop (min-width: 1024px) */
@media only screen and (min-width: 1024px) {
  .home-item-container {
    grid-template-columns: repeat(2, 1fr); /* 3 columns on desktop */
  }
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 2px;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 600px) {
    width: 2.5rem !important;
    height: 2.5rem;
    font-size: 1.2rem;
  }
  @media screen and (max-width: 300px) {
    width: 2rem !important;
    height: 2rem;
    font-size: 1rem;
  }

}

// p{
//     margin: 0px;
// }

* {
  cursor: pointer;
}

.PhoneInputInput,
.PhoneInputCountry {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

/* Style the selected country flag */
.PhoneInputCountrySelect {
  padding: 5px;
  border-radius: 5px;
}

.react-modal-sheet-container > div > div > div {
  &.primary {
    color: #605d6c;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    line-height: normal;

    &.strong {
      font-weight: 500;
      color: #1b1a20;
    }
  }

  &.button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;

    .primary-button {
      background-color: $primary-color;
      color: white;
      padding: 10px;
      width: -webkit-fill-available;
      text-align: center;
      font-family: Poppins;
      border-width: 2px;
      border-style: solid;
      border-color: $primary-color;
    }

    .secondary-button {
      border-color: $primary-color;
      color: #1b1a20;
      padding: 10px;
      width: -webkit-fill-available;
      text-align: center;
      border-width: 2px;
      border-style: solid;
      font-family: Poppins;
    }
  }
}

.pickup-delivery-switch {
  margin: 20px 10px 20px 10px;
}

.switch-container {
  position: relative;
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.switch {
  position: absolute;
  width: 50%;
  height: 90%;
  background-color: $primary-color;
  border-radius: 8px;
  cursor: pointer;
}

.switch-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
  font-size: 14px;
  font-family: Poppins;
}

.switch-labels span {
  cursor: pointer;
  transition: 0.3s ease;
  flex: 1;
  text-align: center;
  /* Center-align text */
}

.switch-labels span.active {
  font-weight: bold;
  color: white;
  z-index: 1;
}

.home-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
  padding: 10px;
  box-shadow: 4px 6px 15px 0px rgba(0, 0, 0, 0.06);
}