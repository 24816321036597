.offer-container {
  border-radius: 8px;
  background: #eeebfc;
  display: flex;
  margin: 10px;

  .first {
    flex: 0.7;
    padding: 10px;

    .title {
      color: #1b1a20;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .description {
      color: #1b1a20;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .second {
    flex: 0.3;
    border-radius: 0px 8px 8px 0px;
    padding: 10px;
    background: $primary-color;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.offer-wrapper {
  --height-var: 250px;

  height: var(--height-var);
  width: 80%;

  margin-top: 30px;
  margin-bottom: 40px;

  position: relative;

  & img {
    width: 95%;
    height: var(--height-var);
    object-fit: cover;
    border-radius: 8px;
  }

  & .left,
  & .right {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: none;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .left {
    left: 0px;
    transform: translate(-50%, -50%);
  }
  & .right {
    transform: translate(20%, -50%);
    right: 0px;
  }

  & .left.disabled,
  & .right.disabled {
    display: none;
  }
}

.offer_wrapper_small {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  & img {
    width: 400px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    @media screen and (max-width: 768px) {
      width: 300px;
      height: 150px;
    }
  }
  // max-height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .offer-wrapper {
    width: 95%;

    & img {
      width: 95%;
    }

    & .left,
    & .right {
        display: none;
    }
  }
}
