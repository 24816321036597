.order-container {
  * {
    cursor: pointer;
  }

  margin: 16px;

  .title {
    color: #605d6c;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.28px;
    text-align: center;
    margin: 0;
    margin-top: 10px;
  }

  .order-list-container {
    background: white;
    border-radius: 8px;
    margin-top: 10px;
    box-shadow: 10px 6px 15px 10px rgba(0, 0, 0, 0.04);

    .order-item {
      padding: 16px;

      .order-id {
        color: #433f55;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .caption {
        color: #605d6c;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        &.strong {
          font-weight: 500;
          color: #1b1a20;
        }

        &.underline {
          text-decoration: underline;
        }

        /* 150% */
      }

      .btn {
        padding: 3px 16px;
        gap: 10px;
        border-radius: 5px;
        box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.06);
        background: #f5f5f5;
        font-size: 16px;
        font-weight: 500;

        &.fill {
          background: #000;
          color: white;
        }
      }

      .dot {
        width: 6px;
        height: 6px;
        flex-shrink: 0;
        background: #1b1a20;
        border-radius: 3px;
        margin: 0px 5px;
      }

      .description {
      }

      .secondary {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}

.order-detail-container {
  margin: 16px auto;
  max-width: 800px;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.06);
    height: 50px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #000;

    .btn-title {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .title {
    color: #605d6c;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.28px;

    &.center {
      font-size: 16px;
      text-align: center;
      margin: 0;
      margin-top: 10px;

      &.strong {
        color: #000;
        font-size: 20px;
        letter-spacing: 1.6px;
      }
    }
  }

  .caption {
    color: #605d6c;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.strong {
      font-weight: 500;
      color: #1b1a20;
    }
  }

  .items-container,
  .invoice-container {
    border-radius: 8px;
    background: #ffffff;
    padding: 10px;
    box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.04);
    margin-bottom: 10px;
  }

  .invoice-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px 0px 5px;
    margin-bottom: 5px;

    .subtotal {
      color: #1b1a20;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .invoice-price {
      color: $primary-color;
      text-align: right;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .total-price {
      color: #1b1a20;
      text-align: right;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .invoice-title {
      color: #605d6c;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
